import React, { useState, useEffect } from "react";
import { useRef } from 'react';
import "../../components/homebanner/homebanner.css";
import closemodal from "../../images/closemodal.png";
import CountrySelector from "../Country Selector/CountrySelector";
import sademo from "../../images/sademo.png";
import "react-phone-number-input/style.css";
import "./Navbar.css";
import NavLogo from "../../images/navlogo.png";
import darkNavLogo from '../../images/CallTracking/dark-nav-logo.svg'
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import finance from "../../images/navicons/finance.png";
import insurrence from "../../images/navicons/insurrence.png";
import Ecommerce from "../../images/navicons/Ecommerce.png";
import Logistic from "../../images/navicons/Logistic.png";
import Restaurant from "../../images/navicons/Restaurant.png";
import contactcenteer from "../../images/navicons/contactcenter.svg";
import asr from "../../images/navicons/ASR.svg";
import cloud from '../../images/navicons/cloud.svg'
import tracking from '../../images/navicons/tracking.svg'
import thankyouvector from '../../images/thankyouvector.svg'
import {
  Navbar,
  Nav,
  NavDropdown,
  Modal,
  Form,
  Button,
} from "react-bootstrap";



function NavBar() {
  const [tabsMob, SetTabsMob] = useState(0);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  useEffect(() => {
    console.log(query.get('tab'))
  }, [query])

  useEffect(() => {
    if (query.get('tab')) {
      SetTabsMob(query.get('tab'));
      const element = document.getElementById('tab1');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }, [query]);

  const [expanded, setExpanded] = useState(false);
  const [minus, setMinus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal3, setshowModal3] = useState(false);



  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal3 = () => setshowModal3(false)
  const location = useLocation();
  const form = useRef();
  useEffect(() => {
    setExpanded(false);
  }, [location]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    country: '',
    desk: '',
    phoneNumber: ''
  });


  const [errors, setErrors] = useState({});
  function showError(inputName, message) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: message,
    }));
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    showError(name, '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm(formData);
    console.log(Object.keys(validationErrors).length)
    if (Object.keys(validationErrors).length === 0) {
      console.log('Form is valid ')
      setShowModal(false);
      setshowModal3(true);
      const config = {
        SecureToken: '55c37aa3-9b8d-4773-9e02-be1801bb19d8',
        To: 'info@callwise.ai',
        From: "info@callwise.ai",
        Subject: 'Demo Scheduled',
        Body: `Name :${formData.name}, 
               Email: ${formData.email}, 
               Company: ${formData.company}, 
               Country: ${formData.country.value}, 
               Phone: ${formData.phoneNumber}, 
               What calls do you look forward to automate? : ${formData.desk}`
      }
      window.Email.send(config).then((e, c, p, d) => console.log(e, c, p, d))
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.name || data.name.length < 3 || data.name.length > 25) {
      errors.name = 'Name should be between 3 and 25 characters*';
    }

    if (!data.email) {
      errors.email = 'Email is required*';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Invalid email format*';
    }

    if (!data.country) {
      errors.country = 'Country is required*';
    }
    if (!data.phoneNumber) {
      errors.phoneNumber = 'Phone number is required*';
    } else if (!/^\d{10}$/.test(data.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number format';
    }

    return errors;
  };

  const callTrackingPage = location.pathname === '/Call-Tracking' || location.pathname === '/demo-page';

  return (
    <>
      <div className="c-nav-p">
        <Navbar expand="lg" expanded={expanded} onToggle={setExpanded}>
          <Navbar.Brand as={Link} to="/" onSelect={() => setExpanded(false)}>
            <img
              className="nav-logo-img"
              src={callTrackingPage ? darkNavLogo : NavLogo}
              alt="logo"
              loading="lazy"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon">
              <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <path
                  stroke="#fff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  d="M4 7h22M4 15h22M4 23h22"
                />
              </svg>
            </span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)} className={callTrackingPage ? 'call-tracking-nav-text' : ''}>
                Home
              </Nav.Link>
              <NavDropdown
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "10px" }} className={callTrackingPage ? 'call-tracking-nav-text' : ''}>Products</span>
                    <span className="nav-signs">{minus ? "-" : "+"}</span>
                  </div>
                }
                id="basic-nav-dropdown"
                expanded={expanded}
                onSelect={() => setExpanded(!expanded)}
                onClick={() => setMinus(!minus)}
              >
                <NavDropdown.Item
                  as={Link}
                  to="/finance"
                  className="nav-flex-itm"
                >
                  <img src={contactcenteer} alt="iconsnav" loading="lazy" />
                  <p>
                    Contact Center Ai |<br /> Inbound/OutBound
                  </p>
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/features"
                  className="nav-flex-itm"
                >
                  <img src={asr} alt="iconsnav" loading="lazy" />
                  <p>ASR as Service</p>
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Cloud"
                  className="nav-flex-itm"
                >
                  <img src={cloud} alt="iconsnav" loading="lazy" />
                  <p>Cloud Call Center</p>
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Call-Tracking"
                  className="nav-flex-itm"
                >
                  <img src={tracking} alt="iconsnav" loading="lazy" />
                  <p>Call Tracking</p>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "10px" }} className={callTrackingPage ? 'call-tracking-nav-text' : ''}>Industries</span>
                    <span className="nav-signs">{minus ? "-" : "+"}</span>
                  </div>
                }
                id="basic-nav-dropdown"
                expanded={expanded}
                onSelect={() => setExpanded(!expanded)}
                onClick={() => setMinus(!minus)}
              >
                <NavDropdown.Item
                  as={Link}
                  to="/finance?tab=0"
                  className="nav-flex-itm"
                >
                  <img src={finance} alt="iconsnav" loading="lazy" />
                  <p>Finance</p>
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/finance?tab=1"
                  className="nav-flex-itm"
                >
                  <img src={insurrence} alt="iconsnav" loading="lazy" />
                  <p>Insurance</p>
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/finance?tab=2"
                  className="nav-flex-itm"
                >
                  <img src={Ecommerce} alt="iconsnav" loading="lazy" />
                  <p>Ecommerce & Retail</p>
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/finance?tab=3"
                  className="nav-flex-itm"
                >
                  <img src={Logistic} alt="iconsnav" loading="lazy" />
                  <p>Logistic & Supply Chain</p>
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/finance?tab=4"
                  className="nav-flex-itm"
                >
                  <img src={Restaurant} alt="iconsnav" loading="lazy" />
                  <p>Restaurant Industry</p>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                href="https://www.idrakai.com/"
                onSelect={() => setExpanded(false)}
                target="_blank"
                className={callTrackingPage ? 'call-tracking-nav-text' : ''}
              >
                About us
              </Nav.Link>
              {/* <Nav.Link
                as={Link}
                to="/media-gallery"
                onSelect={() => setExpanded(false)}
              >
                Blog
              </Nav.Link> */}
            </Nav>
            <Form inline>
              <ScrollLink to="bottom-footer" smooth={true}>
                <Button href="/" className={callTrackingPage ? 'call-tracking-login-c' : 'login-c'} onClick={handleShowModal} >
                  Schedule a demo
                </Button>
              </ScrollLink>
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </div>

      {/* modal */}
      <Modal
        className="modal-banner-wrapper"
        id='demo-modal'
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <img src={closemodal} onClick={handleCloseModal} alt="" />
        </Modal.Header>
        <Modal.Body>
          <div className="modaldollor">
            <img className="sademo-img-modal" src={sademo} alt="" />
            <h4>Do you want to </h4>
            <h3>Schedule a demo? </h3>
          </div>

          <form ref={form} onSubmit={handleSubmit}>
            {errors.name && <span className="error">{errors.name}</span>}
            <input
              placeholder='Name*'
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
            <input
              placeholder='Email*'
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.company && <span className="error">{errors.company}</span>}
            <input
              placeholder='Company Name'
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
            />
            {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
            <div className="d-flex" >
              <CountrySelector setData={setFormData} data={formData} />

              <input
                placeholder='Phone Number*'
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                inputMode="numeric"

                required
              />
            </div>

            {errors.desk && <span className="error">{errors.desk}</span>}
            <input
              placeholder='What type of calls are you looking forward to automate'
              type="text"
              name="desk"
              value={formData.desk}
              onChange={handleChange}
            />

            <div className="btn-mdl-wrapper">
              <Button className="btn-mdl-red" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showModal3} onHide={handleCloseModal3} className="submit-modal">
        <div className="submit-modal-content">
          <img src={thankyouvector} alt="" />
          <h1>Thank you!</h1>
          <p>Your submission has been sent.</p>
        </div>
      </Modal>
    </>
  );
}

export default NavBar;
