import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from 'react';
import { useState } from "react";
import footerlogo from "../../images/footerlogo.png";
import closemodal from "../../images/closemodal.png";
import modaldollor from "../../images/modaldollor.png";
import sademo from "../../images/sademo.png";
import "react-phone-number-input/style.css";
import thankyouvector from '../../images/thankyouvector.svg'
import CountrySelector from "../Country Selector/CountrySelector";
import { Link } from 'react-router-dom';
import {
  Modal,
  Button,
} from "react-bootstrap";
import "./Footer.css";
import { FbIcon, IgIcon, InIcon, TwtIcon } from "../Icons";
const Footer = () => {

  const [expandedSections, setExpandedSections] = useState({});
  const isMobile = window.innerWidth <= 600;
  const toggleContent = (sectionId) => {
    setExpandedSections((prevExpandedSections) => ({
      [sectionId]: !prevExpandedSections[sectionId]
    }));
  };
  const location = useLocation();

  useEffect(() => {

    if (location.hash) {
      const element = document.getElementById(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);


  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);


  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal2 = () => setShowModal2(false);
  const handleShowModal2 = () => setShowModal2(true);
  const handleCloseModal3 = () => setshowModal3(false)
  const images = [
    {
      img: FbIcon,
      link: "https://www.facebook.com/callwiseai",
    },
    {
      img: IgIcon,
      link: "https://www.instagram.com/callwiseai/",
    },
    {
      img: TwtIcon,
      link: "https://twitter.com/Callwiseai",
    },

    {
      img: InIcon,
      link: "https://www.linkedin.com/company/callwise-ai/?viewAsMember=true",
    },
  ];
  function ImageLink({ data }) {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
      setHovered(true);
    };

    const handleMouseLeave = () => {
      setHovered(false);
    };
    const imgColor = hovered ? "#E31E25" : "white"; // Replace "red" with the desired hover color
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 914);
      };

      // Initial check and event listener setup
      handleResize();
      window.addEventListener('resize', handleResize);

      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);



    return (
      <a
        href={data.link}
        target="_blank"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <data.img color={imgColor}/>
      </a>
    );
  }
  const form = useRef();
  const [showModal3, setshowModal3] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    country: '',
    desk: '',
    phoneNumber: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    // Reset error and status for the field being edited
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm(formData);
    console.log(Object.keys(validationErrors).length)
    if (Object.keys(validationErrors).length === 0) {
      console.log('Form is valid ')
      setShowModal(false)
      setShowModal2(false);
      setshowModal3(true);
      const config = {
        SecureToken: '55c37aa3-9b8d-4773-9e02-be1801bb19d8',
        To: 'info@callwise.ai',
        From: "info@callwise.ai",
        Subject: 'Demo Scheduled',
        Body: `Name :${formData.name}, 
               Email: ${formData.email}, 
               Company: ${formData.company}, 
               Country: ${formData.country.value}, 
               Phone: ${formData.phoneNumber}, 
               What calls do you look forward to automate? : ${formData.desk}`
      }
      window.Email.send(config).then((e, c, p, d) => console.log(e, c, p, d))
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.name || data.name.length < 3 || data.name.length > 25) {
      errors.name = 'Name should be between 3 and 25 characters*';
    }

    if (!data.email) {
      errors.email = 'Email is required*';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Invalid email format*';
    }


    if (!data.country) {
      errors.country = 'Country is required*';
    }

    if (!data.phoneNumber) {
      errors.phoneNumber = 'Phone number is required*';
    } else if (!/^\d{10}$/.test(data.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number format';
    }

    return errors;
  };

  return (
    <>
      <div className="footer-top-d">
        <div className="footer-main ">
          <div className="foot1 footc ">
            <h4>Product</h4>
            <a href="">
              {" "}
              <p>Contact Centre Ai</p>
            </a>
            <a href="/Features">
              {" "}
              <p>ASR</p>
            </a>
            <a href="/Features">
              {" "}
              {/* <p>Cloud Call Center</p> */}
            </a>
          </div>
          <div className={`foot1a footc ${isMobile && expandedSections["product"] ? "expanded" : ""}`}>
            <div className="footer-heading" onClick={() => toggleContent("product")}>
              <h4>Product</h4>
              <span className={`arrow ${expandedSections["product"] ? "up" : "down"}`}> &nbsp; &#9660;</span>
            </div>
            {isMobile && expandedSections["product"] && (
              <div>
                <a href=""><p>Contact Centre Ai</p></a>
                <a href="/Features"><p>ASR</p></a>
                {/* <a href="/Cloud"><p>Cloud Call Center</p></a> */}

              </div>
            )}
          </div>
          <div className="foot1 footc ">
            <h4>Industries</h4>
            <Link to="/finance?tab=0">
              {" "}
              <p>Finance</p>
            </Link>
            <Link to="/finance?tab=1">
              {" "}
              <p>Insurance</p>
            </Link>
            <Link to="/finance?tab=2">
              {" "}
              <p>E-commerce and Retail</p>
            </Link>
            <Link to="/finance?tab=3">
              {" "}
              <p>Logistics & Supply Chain</p>
            </Link>
            <Link to="/finance?tab=4">
              {" "}
              <p>Restaurant Industry</p>
            </Link>
          </div>
          <div className={`foot1a footc ${isMobile && expandedSections["Industries"] ? "expanded" : ""}`}>
            <div className="footer-heading" onClick={() => toggleContent("Industries")}>
              <h4>Industries</h4>
              <span className={`arrow ${expandedSections["Industries"] ? "up" : "down"}`}> &nbsp; &#9660;</span>
            </div>
            {isMobile && expandedSections["Industries"] && (
              <div>
                <Link to="/finance?tab=0">
                  {" "}
                  <p>Finance</p>
                </Link>
                <Link to="/finance?tab=1">
                  {" "}
                  <p>Insurance</p>
                </Link>
                <Link to="/finance?tab=2">
                  {" "}
                  <p>E-commerce and Retail</p>
                </Link>
                <Link to="/finance?tab=3">
                  {" "}
                  <p>Logistics & Supply Chain</p>
                </Link>
                <Link to="/finance?tab=4">
                  {" "}
                  <p>Restaurant Industry</p>
                </Link>
              </div>
            )}
          </div>
          <div className="foot1 footc ">
            <h4>Company</h4>
            <a href="/">
              {" "}
              <p>Contact</p>
            </a>
            <a href="/">
              {" "}
              <p>Careers</p>
            </a>
            <a href="https://www.idrakai.com/products/">
              {" "}
              <p>About</p>
            </a>
          </div>
          <div className={`foot1a footc ${isMobile && expandedSections["Company"] ? "expanded" : ""}`}>
            <div className="footer-heading" onClick={() => toggleContent("Company")}>
              <h4>Company</h4>
              <span className={`arrow ${expandedSections["Company"] ? "up" : "down"}`}> &nbsp; &#9660;</span>
            </div>
            {isMobile && expandedSections["Company"] && (
              <div>
                <a href="/">
                  {" "}
                  <p>Contact</p>
                </a>
                <a href="/">
                  {" "}
                  <p>Careers</p>
                </a>
                <a href="https://www.idrakai.com/products/">
                  {" "}
                  <p>About</p>
                </a>
              </div>
            )}
          </div>
          <div className="foot1 footc ">
            <h4>Platform</h4>
            <a href="/Features">
              {" "}
              <p>Features</p>
            </a>
            <div className="pickdemo" onClick={handleShowModal2}>
              {" "}
              <p>Pricing</p>
            </div>
            <div className='pickdemo' onClick={handleShowModal}>
              {" "}
              <p>Pick a Demo</p>
            </div>
          </div>
          <div className={`foot1a footc ${isMobile && expandedSections["Platform"] ? "expanded" : ""}`}>
            <div className="footer-heading" onClick={() => toggleContent("Platform")}>
              <h4>Platform</h4>
              <span className={`arrow ${expandedSections["Platform"] ? "up" : "down"}`}> &nbsp; &#9660;</span>
            </div>
            {isMobile && expandedSections["Platform"] && (
              <div>
                <a href="/Features">
                  {" "}
                  <p>Features</p>
                </a>
                <div className="pickdemo" onClick={handleShowModal2}>
                  {" "}
                  <p>Pricing</p>
                </div>
                <div className='pickdemo' onClick={handleShowModal}>
                  {" "}
                  <p>Pick a Demo</p>
                </div>
              </div>
            )}
          </div>
          <div className="foot1 footc ">
            <h4>Resources</h4>
            <a href="/">
              {" "}
              <p>FAQs</p>
            </a>
            <a href="/">
              {" "}
              <p>Blogs</p>
            </a>
            <Link to="/finance#video-tab">
              {" "}
              <p>Videos</p>
            </Link>
          </div>
          <div className={`foot1a footc ${isMobile && expandedSections["Resources"] ? "expanded" : ""}`}>
            <div className="footer-heading" onClick={() => toggleContent("Resources")}>
              <h4>Resources</h4>
              <span className={`arrow ${expandedSections["Resources"] ? "up" : "down"}`}> &nbsp; &#9660;</span>
            </div>
            {isMobile && expandedSections["Resources"] && (
              <div>
                <a href="/">
                  {" "}
                  <p>FAQs</p>
                </a>
                <a href="/">
                  {" "}
                  <p>Blogs</p>
                </a>
                <Link to="/finance#video-tab">
                  {" "}
                  <p>Videos</p>
                </Link>
              </div>
            )}
          </div>
        </div>
        <div>
          {isMobile ? (
            <div className="footerlogo-flex">
              <div className="footerlogo-right">
                <div className="footerlogo-right-c">
                  <h4>Email:</h4>
                  <a href="mailto:info@callwise.ai">info@callwise.ai</a>
                </div>
                <div className="footerlogo-right-c b-f-r-l">
                  <h4>Phone No:</h4>
                  <a href="tel:+44 (7902) 751142">+44 (7902) 751142</a>
                </div>
                <div className="footerlogo-right-c">
                  <h4>Address:</h4>
                  <a href="https://maps.app.goo.gl/9fCvzMXM4yKwDyjT7" target="_blank">
                    1.6 Southgate Business Park, Normanton Road, Derby, England, DE23 6UQ
                  </a>
                </div>
                <div className="logos-bottom">
                  <div className="footerlogo-left">
                    <img src={footerlogo} alt="logo" />
                  </div>
                  <div className="social-iconsmobile">
                    {images.map((data, index) => (
                      <ImageLink key={index} data={data} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="footerlogo-flex">
              <div className="footerlogo-left">
                <img src={footerlogo} alt="logo" />
              </div>
              <div className="footerlogo-right">
                <div className="footerlogo-right-c">
                  <h4>Email:</h4>
                  <a href="mailto:info@callwise.ai">info@callwise.ai</a>
                </div>
                <div className="footerlogo-right-c b-f-r-l">
                  <h4>Phone No:</h4>
                  <a href="tel:+44 (7902) 751142">+44 (7902) 751142</a>
                </div>
                <div className="footerlogo-right-c">
                  <h4>Address:</h4>
                  <a href="https://maps.app.goo.gl/9fCvzMXM4yKwDyjT7" target="_blank">
                    1.6 Southgate Business Park, Normanton Road, Derby, England, DE23 6UQ
                  </a>
                </div>

                <div className="socail-icons">
                  {images.map((data, index) => (
                    <ImageLink key={index} data={data} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="footer-copyright">
          <div className="d-flex">
            <a href='/terms'><p>Terms and Condition <span className="seperator"> | </span></p></a>
            <a href="/privacy"><p>&nbsp;Privacy and Policy</p></a></div>
          <p>Copyright @ 2024. All rights reserved</p>
        </div>
      </div>
      <Modal
        className="modal-banner-wrapper"
        id='demo-modal'
        show={showModal}
        onHide={handleCloseModal}

      >
        <Modal.Header>
          <img src={closemodal} onClick={handleCloseModal}  alt=""/>
        </Modal.Header>
        <Modal.Body>
          <div className="modaldollor">
            <img className="sademo-img-modal" src={sademo} alt=""/>
            <h4>Do you want to </h4>
            <h3>Schedule a demo? </h3>
          </div>

          <form ref={form} onSubmit={handleSubmit}>
            {errors.name && <span className="error">{errors.name}</span>}
            <input
              placeholder='Name*'
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
            <input
              placeholder='Email*'
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.company && <span className="error">{errors.company}</span>}
            <input
              placeholder='Company Name'
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
            />
            {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
            <div className="d-flex" >
              <CountrySelector setData={setFormData} data={formData} />
              <input
                placeholder='Phone Number*'
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                inputMode="numeric"

                required
              />
            </div>

            {errors.desk && <span className="error">{errors.desk}</span>}
            <input
              placeholder='What type of calls are you looking forward to automate'
              type="text"
              name="desk"
              value={formData.desk}
              onChange={handleChange}
            />

            <div className="btn-mdl-wrapper">
              <Button className="btn-mdl-red" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div>
        <Modal
          className="modal-banner-wrapper"
          show={showModal2}
          onHide={handleCloseModal2}
          id='demo-modal'

        >
          <Modal.Header>
            <img src={closemodal} onClick={handleCloseModal2}  alt=""/>
          </Modal.Header>
          <Modal.Body>
            <div className="modaldollor">
              <img src={modaldollor}  alt=""/>
              <h4>Do you want to </h4>
              <h3>Claim your Dollars? </h3>
            </div>

            <form onSubmit={handleSubmit}>
              {errors.name && <span className="error">{errors.name}</span>}
              <input
                placeholder='  Name*'
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.email && <span className="error">{errors.email}</span>}
              <input
                placeholder='  Email*'
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.company && <span className="error">{errors.company}</span>}
              <input
                placeholder=' Company  Name*'
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
              />
              {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
              <div className="d-flex" >
                <CountrySelector setData={setFormData} data={formData} />
                <input
                  placeholder='Phone Number*'
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  inputMode="numeric"

                  required
                />
              </div>
              {errors.desk && <span className="error">{errors.desk}</span>}
              <input
                placeholder='What type of calls are you looking forward to automate'
                type="text"
                name="desk"
                value={formData.desk}
                onChange={handleChange}
              />

              <div className="btn-mdl-wrapper">
                <Button className="btn-mdl-red" onClick={handleSubmit}>
                  Claim your $s
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal show={showModal3} onHide={handleCloseModal3} className="submit-modal">
          <div className="submit-modal-content">
            <img src={thankyouvector}  alt=""/>
            <p>Thank you for your submission. We'll be in touch soon!</p>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Footer;
