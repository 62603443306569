import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Terms from "./components/Terms/Terms";
import NavBar from "./components/Navbar/NavBar";
import Home from "./pages/home/Home";
import Industries from "./pages/Industries/Finance";
import Footer from "./components/Footer/Footer";
import Features from "./pages/Features/Features";
import Privacy from "./components/Privacy/Privacy";
import Cloud from "./components/CloudCall/Cloud";
import CallTracking from "./components/Call Tracking/CallTracking";
import AudioStreamingApp from "./components/DemoPage/AudioStreamingApp";

const App = () => {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route exact element={<Home />} path="/" />
        </Routes>
        <Routes>
          <Route element={<Industries />} path="/finance" />
        </Routes>

        <Routes>
          <Route exact element={<Features />} path="/features" />
        </Routes>

        <Routes>
          <Route exact element={<Privacy />} path="/privacy" />
        </Routes>

        <Routes>
          <Route exact element={<Terms />} path="/terms" />
        </Routes>
        <Routes>
          <Route exact element={<Cloud />} path="/Cloud" />
        </Routes>
        <Routes>
          <Route exact element={<CallTracking />} path="/Call-Tracking" />
        </Routes>
        <Routes>
          <Route exact element={<AudioStreamingApp />} path="/demo-page" />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
