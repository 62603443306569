import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import {
  Container,
  Typography,
  Button,
  Grid,
  Paper,
  Card,
  CardContent,
  Alert,
} from "@mui/material";
import './Audio.css'
const socket = io("http://148.251.195.218:5001/");

function AudioStreamingApp() {
  const [isStreaming, setIsStreaming] = useState(false);
  const [transcription, setTranscription] = useState("");
  const [error, setError] = useState("");
  const [debug, setDebug] = useState("");
  const audioContextRef = useRef(null);
  const streamSourceRef = useRef(null);
  const processorRef = useRef(null);
  const currentAudioRef = useRef(null);
  const audioQueueRef = useRef([]);
  const isProcessingAudioRef = useRef(false);
  const debugLogRef = useRef(null);

  const log = (message) => {
    const timestamp = new Date().toLocaleTimeString();
    setDebug((prevDebug) => `${prevDebug}[${timestamp}] ${message}\n`);
  };

  useEffect(() => {
    socket.on("connect", () => log("Connected to server"));
    socket.on("disconnect", () => log("Disconnected from server"));
    socket.on("stop_listening", () => {
      log("Received stop_listening event");
      stopStreaming(false);
    });
    socket.on("transcription", handleTranscription);
    socket.on("error", (data) => {
      log("Server error: " + data.message);
      setError("Server error: " + data.message);
    });

    return () => {
      socket.off("transcription", handleTranscription);
      socket.off("error");
      socket.off("stop_listening"); // Ensure to clean up the event listener
    };
  }, []);

  socket.on("stop_tts", () => {
    isProcessingAudioRef.current = false;
    stopTTS();
  });

  useEffect(() => {
    if (debugLogRef.current) {
      debugLogRef.current.scrollTop = debugLogRef.current.scrollHeight;
    }
  }, [debug]);

  // Producer-Consumer pattern for handling audio blobs

  const producer = (blob) => {
    audioQueueRef.current.push(blob);
  };

  const consumer = async () => {
    isProcessingAudioRef.current = true;

    const audioBlob = audioQueueRef.current.shift();
    handleTTSPlayback(audioBlob);
  };

  const processAudioBlob = async (blob) => {
    try {
      const audioData = await blob.arrayBuffer();
      socket.emit("audio_chunk", audioData);
      handleTTSPlayback(blob);
    } catch (error) {
      log("Error processing audio blob: " + error.message);
    }
  };

  const handleTranscription = (data) => {
    log("Received transcription: " + data.text);
    setTranscription((prev) => prev + " " + data.text);

    producer(data.audio);
    if (!isProcessingAudioRef.current) {
      consumer();
    }
  };

  const handleTTSPlayback = async (audioData) => {
    try {
      const blob = new Blob([audioData], { type: "audio/wav" });
      const audioUrl = URL.createObjectURL(blob);
      const audio = new Audio(audioUrl);

      currentAudioRef.current = audio;
      await audio.play();
      audio.onended = () => {
        currentAudioRef.current = null;
        URL.revokeObjectURL(audioUrl);
        if (audioQueueRef.current.length > 0) {
          consumer();
        } else {
          isProcessingAudioRef.current = false;
        }
      };
    } catch (error) {
      log("Error processing TTS audio: " + error.message);
      setError("Error processing TTS audio: " + error.message);
    }
  };

  const toggleStreaming = async () => {
    if (isStreaming) {
      stopStreaming();
    } else {
      await startStreaming();
    }
  };

  const startStreaming = async () => {
    try {
      log("Requesting microphone access...");
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 16000,
        },
      });

      log("Microphone access granted");
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 16000,
      });
      const source = audioCtx.createMediaStreamSource(stream);
      const scriptProcessor = audioCtx.createScriptProcessor(1024, 1, 1);

      source.connect(scriptProcessor);
      scriptProcessor.connect(audioCtx.destination);

      let audioChunks = [];
      scriptProcessor.onaudioprocess = (e) => {
        const inputData = e.inputBuffer.getChannelData(0);
        audioChunks.push(...inputData);
        if (audioChunks.length >= 8000) {
          const audioData = convertFloat32ToInt16(audioChunks);
          log(`Sending audio chunk (${audioData.length} samples)`);
          socket.emit("audio", audioData.buffer);
          audioChunks = [];
        }
      };

      audioContextRef.current = audioCtx;
      streamSourceRef.current = source;
      processorRef.current = scriptProcessor;
      setIsStreaming(true);
      setError("");
    } catch (error) {
      setError("Error accessing microphone: " + error.message);
      log("Error: " + error.message);
    }
  };

  const stopStreaming = (isEmit = true) => {
    if (processorRef.current) {
      processorRef.current.disconnect();
      processorRef.current.onaudioprocess = null; // Clear the audio process event
    }
    if (streamSourceRef.current) {
      streamSourceRef.current.disconnect();
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
    setIsStreaming(false);
    log("Stopped audio streaming");
    if (isEmit) {
      socket.emit("stop_streaming");
    }
    stopTTS();

    // Clear any remaining audio chunks
    audioQueueRef.current = [];
    currentAudioRef.current = null;
  };

  const stopTTS = () => {
    if (currentAudioRef.current) {
      currentAudioRef.current.pause();
      currentAudioRef.current.currentTime = 0;
      currentAudioRef.current = null;
      audioQueueRef.current = [];
      socket.emit("tts_ended");
    }
  };

  const convertFloat32ToInt16 = (float32Array) => {
    const int16Array = new Int16Array(float32Array.length);
    for (let i = 0; i < float32Array.length; i++) {
      const s = Math.max(-1, Math.min(1, float32Array[i]));
      int16Array[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
    }
    return int16Array;
  };

  return (
    <Container
      maxWidth="100%"
      className="demo-main"
      sx={{ marginTop: 8 }}
      style={{ paddingTop: "10%", paddingBottom: "5%", marginTop: 0, position: 'relative' }}
    >
      <Card elevation={6} className="inner-section">
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom color='#fff'>
            Idrak AI Live Bot
          </Typography>

          <Grid
            container
            spacing={4}
            justifyContent="center"
            sx={{
              marginBottom: 4,
              zIndex: 1
            }}
          >
            <Grid item>
              <Button
                variant="contained"
                className="demo-button"
                color={isStreaming ? "error" : "primary"}
                onClick={toggleStreaming}
              >
                {isStreaming ? "Stop Talking" : "Start Talking"}
              </Button>
            </Grid>
          </Grid>

          {error && (
            <Alert severity="error" sx={{ marginBottom: 4 }}>
              {error}
            </Alert>
          )}

          <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }} className="sub-section">
            <Typography variant="h4" color='#fff' sx= {{ paddingBottom: '10px'}}>Transcription</Typography>
            <Paper
              elevation={1}
              className="sub-section"
              sx={{
                backgroundColor: "#f5f5f5",
                padding: 2,
                height: 150,
                overflowY: "auto",
              }}
            >
              {transcription || (
                <Typography color="#A3A3A3">
                  No transcription available yet.
                </Typography>
              )}
            </Paper>
          </Paper>

          <Paper elevation={3} sx={{ padding: 2 }} className="sub-section">
            <Typography variant="h4" color='#fff' sx= {{ paddingBottom: '10px'}}>Debug Log</Typography>
            <div
              ref={debugLogRef}
              className="sub-section"
              style={{
                padding: "16px",
                height: "150px",
                overflowY: "auto",
                color: '#A3A3A3'
              }}
            >
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {debug}
              </Typography>
            </div>
          </Paper>
        </CardContent>
      </Card>
    </Container >
  );
}

export default AudioStreamingApp;
